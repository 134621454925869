import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const Navigation = ({ businessName, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    // Debug incoming props
    useEffect(() => {
        console.log('Navigation - Received props:', {
            businessName,
            content
        });
    }, [businessName, content]);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector('.preview-scroll-container');
            if (element) {
                setIsScrolled(element.scrollTop > 50);
            }
        };

        const scrollContainer = document.querySelector('.preview-scroll-container');
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            return () => scrollContainer.removeEventListener('scroll', handleScroll);
        }
    }, []);

    // Use generated navigation content or fallback to defaults
    const navItems = content?.links || [
        { label: 'Home', href: '#' },
        { label: 'Services', href: '#' },
        { label: 'Events', href: '#' },
        { label: 'Contact', href: '#' }
    ];

    const cta = content?.cta || {
        label: 'Book Now',
        href: '#book'
    };

    // Debug what content is actually being used
    console.log('Navigation - Using content:', {
        navItems,
        cta,
        isUsingDefaults: !content?.links
    });

    return (
        <div className={`sticky top-0 z-50 transition-all duration-300 ${
            isScrolled ? 'bg-white shadow-sm' : 'bg-transparent'
        }`}>
            <div className="container mx-auto">
                <div className="flex justify-between items-center h-20 px-8">
                    {/* Logo/Business Name */}
                    <div className="flex-shrink-0 flex items-center">
                        <a href="#" className={`text-xl font-bold transition-colors duration-300 ${
                            isScrolled ? 'text-gray-900' : 'text-white'
                        }`}>
                            {businessName}
                        </a>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-8">
                        {navItems.map(item => (
                            <a
                                key={item.label}
                                href={item.href}
                                className={`px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                                    isScrolled
                                        ? 'text-gray-600 hover:text-gray-900'
                                        : 'text-white/90 hover:text-white'
                                }`}
                            >
                                {item.label}
                            </a>
                        ))}
                        <a
                            href={cta.href}
                            className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm font-medium hover:bg-blue-700 transition-colors"
                        >
                            {cta.label}
                        </a>
                    </div>

                    {/* Mobile menu button */}
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className={`inline-flex items-center justify-center p-2 rounded-md transition-colors ${
                                isScrolled
                                    ? 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                                    : 'text-white hover:text-white/80'
                            }`}
                        >
                            {isOpen ? (
                                <X className="block h-6 w-6" />
                            ) : (
                                <Menu className="block h-6 w-6" />
                            )}
                        </button>
                    </div>
                </div>

                {/* Mobile Navigation */}
                {isOpen && (
                    <div className="md:hidden">
                        <div className={`px-8 pt-2 pb-3 space-y-1 ${
                            isScrolled ? 'bg-white' : 'bg-black/80 backdrop-blur-sm'
                        }`}>
                            {navItems.map(item => (
                                <a
                                    key={item.label}
                                    href={item.href}
                                    className={`block px-3 py-2 rounded-md text-base font-medium ${
                                        isScrolled
                                            ? 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                                            : 'text-white/90 hover:text-white hover:bg-white/10'
                                    }`}
                                >
                                    {item.label}
                                </a>
                            ))}
                            <a
                                href={cta.href}
                                className="block w-full mt-2 bg-blue-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-blue-700 transition-colors text-center"
                            >
                                {cta.label}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navigation;