import React from 'react';
import {ShieldCheck, Sparkles, ArrowRight, Globe, Wallet, Check} from 'lucide-react';
import teamIllustration from "../../img/undraw/team.svg";
import Button from '../../shared/Button';

const WelcomeIntroduction = ({ onContinue }) => {
    return (
        <div className="fixed inset-0 flex flex-col bg-gray-50">
            {/* Fixed Header */}
            <div className="sticky top-0 z-10 bg-white border-b">
                <div className="h-16 px-4 md:px-6 flex items-center justify-between max-w-6xl mx-auto">
                    <div className="text-xl font-bold text-blue-600">Jimdo</div>
                </div>
                {/* Compliance Banner */}
                <div className="bg-green-50 border-t border-green-100 py-2">
                    <div className="max-w-6xl mx-auto px-4 md:px-6">
                        <div className="flex items-center justify-center gap-2 text-sm text-green-700">
                            <ShieldCheck className="h-4 w-4" />
                            <span>Your business is protected with built-in legal compliance</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto">
                <div className="max-w-6xl mx-auto px-4 md:px-6 py-12">
                    {/* Main Content */}
                    <div className="grid lg:grid-cols-2 gap-12 items-center">
                        {/* Left Column - Content */}
                        <div className="space-y-8">
                            {/* Headline */}
                            <div>
                                <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
                                    Ready to grow your business with your own professional website?
                                </h1>
                                <p className="text-xl text-gray-600 mt-4">
                                    Create your very own website with our AI-powered builder in minutes, optimised to attract and convert customers.
                                </p>
                            </div>

                            {/* Key Features */}
                            <div className="space-y-8">
                                <div className="flex gap-3">
                                    <Sparkles className="h-5 w-5 text-blue-600 mt-1 flex-shrink-0" />
                                    <div>
                                        <h3 className="text-base font-medium text-gray-900">Your brand, your way</h3>
                                        <p className="text-gray-600">Let your website shine with personalized content & designs optimized to drive growth.</p>
                                    </div>
                                </div>

                                <div className="flex gap-3">
                                    <Wallet className="h-5 w-5 text-blue-600 mt-1 flex-shrink-0" />
                                    <div>
                                        <h3 className="text-base font-medium text-gray-900">Start making money online from day 1</h3>
                                        <p className="text-gray-600">Easily accept bookings & payments right from your website right from the start.</p>
                                    </div>
                                </div>

                                <div className="flex gap-3">
                                    <Globe className="h-5 w-5 text-blue-600 mt-1 flex-shrink-0" />
                                    <div>
                                        <h3 className="text-base font-medium text-gray-900">Find new customers fast</h3>
                                        <p className="text-gray-600">Boost traffic & grow your revenue with built-in SEO tools, Google ads, and Google local listing.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Social Proof */}
                            <div>
                                <div className="flex items-center gap-2 text-sm text-gray-600 mb-4">
                                    <Check className="h-4 w-4" />
                                    <span>Takes less than 2 minutes</span>
                                    <Check className="h-4 w-4" />
                                    <span>No credit card required</span>
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <p className="text-sm text-gray-900 font-medium">
                                            "Professional website doubled my bookings"
                                        </p>
                                        <p className="text-sm text-gray-600 mt-1">Sarah K., Restaurant Owner</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-900 font-medium">
                                            "Finally, a compliant website that converts"
                                        </p>
                                        <p className="text-sm text-gray-600 mt-1">Mike R., Personal Trainer</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Illustration */}
                        <div className="relative hidden lg:block">
                            <img
                                src={teamIllustration}
                                alt="Team working together"
                                className="w-full max-w-lg mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Fixed Footer */}
            <div className="border-t bg-white">
                <div className="max-w-6xl mx-auto px-4 md:px-6 py-4 flex items-center justify-between">
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                        <ShieldCheck className="h-4 w-4" />
                        Ready to create your legally-compliant website?
                    </div>
                    <Button
                        onClick={onContinue}
                        className="px-6"
                    >
                        Start Growing Your Business
                        <ArrowRight className="h-4 w-4 ml-2" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WelcomeIntroduction;