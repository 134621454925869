// src/components/TaskSystem/TaskCard.jsx
import React from 'react';
import { ChevronRight } from 'lucide-react';

const TaskCard = ({
                      title,
                      description,
                      benefit,
                      icon: Icon,
                      isComplete,
                      onClick,
                      isExpanded
                  }) => {
    return (
        <div
            onClick={onClick}
            className="
                bg-white
                rounded-lg
                border
                border-gray-200
                p-5
                cursor-pointer
                hover:border-gray-300
                hover:shadow-sm
                transition-all
                duration-200
            "
        >
            <div className="flex items-start gap-4">
                {/* Icon */}
                <div className="flex-shrink-0 mt-1">
                    <Icon className="h-5 w-5 text-blue-600" />
                </div>

                {/* Content */}
                <div className="flex-1 min-w-0 space-y-1">
                    <div className="flex justify-between items-center">
                        <h3 className="font-semibold text-gray-900">{title}</h3>
                        <ChevronRight
                            className={`
                                h-5 w-5 
                                text-gray-400 
                                transform 
                                transition-transform 
                                duration-200
                                ${isExpanded ? 'rotate-90' : ''}
                            `}
                        />
                    </div>

                    {/* Description always visible */}
                    <p className="text-sm text-gray-600 mt-1">{description}</p>

                    {/* Benefit shown when expanded */}
                    {isExpanded && benefit && (
                        <div className="mt-4 bg-blue-50 p-4 rounded-lg">
                            <p className="text-sm text-blue-700">
                                <span className="font-medium">Business Impact:</span>
                                <br />
                                {benefit}
                            </p>
                        </div>
                    )}

                    {/* Completion status */}
                    {isComplete && (
                        <div className="mt-2">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Completed
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskCard;