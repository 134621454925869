import React from 'react';
import TaskSystem from '../TaskSystem/';

const Sidebar = ({
                     isOpen,
                     websiteGoal,
                     setWebsiteGoal,
                     taskProgress,
                     setTaskProgress,
                     formData,
                     onContentUpdate
                 }) => {
    return (
        <div className={`
            w-full sm:w-80 md:w-96 lg:w-[448px] 
            h-full sm:h-auto
            bg-white 
            border-l 
            flex-shrink-0 
            flex 
            flex-col 
            fixed 
            right-0 
            top-16
            bottom-0
            transform
            transition-all
            duration-300
            ease-in-out
            z-40
            ${isOpen ? 'translate-x-0' : 'translate-x-full'}
        `}>
            <div className={`
                h-full
                flex
                flex-col
                transition-all
                duration-300
                ease-in-out
                ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'}
            `}>
                <div className="flex-1 overflow-hidden">
                    <TaskSystem
                        websiteGoal={websiteGoal}
                        formData={formData}
                        taskProgress={taskProgress}
                        setTaskProgress={setTaskProgress}
                        onContentUpdate={onContentUpdate}
                    />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;