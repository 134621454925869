import React from 'react';
import { Facebook, Instagram, Twitter, Linkedin, Mail, Phone, MapPin } from 'lucide-react';

const SOCIAL_ICONS = {
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    linkedin: Linkedin
};

const Footer = ({ businessName, content }) => {
    console.log('Footer receiving content:', content);

    const currentYear = new Date().getFullYear();

    // Use generated footer content or fallback to defaults
    const footerContent = content || {
        description: 'Creating unique culinary experiences and unforgettable moments.',
        sections: [
            {
                title: 'Company',
                links: [
                    { label: 'About Us', href: '#' },
                    { label: 'Services', href: '#' },
                    { label: 'Events', href: '#' },
                    { label: 'Contact', href: '#' }
                ]
            },
            {
                title: 'Legal',
                links: [
                    { label: 'Privacy Policy', href: '#' },
                    { label: 'Terms of Service', href: '#' },
                    { label: 'Cookie Policy', href: '#' }
                ]
            },
            {
                title: 'Contact',
                links: [
                    { label: 'Contact Us', href: '#' },
                    { label: 'Support', href: '#' },
                    { label: 'Locations', href: '#' }
                ]
            }
        ],
        social: [
            { platform: 'facebook', href: '#' },
            { platform: 'instagram', href: '#' },
            { platform: 'twitter', href: '#' }
        ],
        contact: {
            email: 'info@example.com',
            phone: '+1 (555) 123-4567',
            location: 'New York, NY'
        }
    };

    if (!footerContent) {
        console.warn('Footer content is missing, using defaults');
    }

    return (
        <footer className="bg-gray-900 text-gray-300">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                {/* Main Footer Content */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12">
                    {/* Brand Section */}
                    <div className="col-span-1">
                        <h3 className="text-xl font-bold text-white mb-4">
                            {businessName}
                        </h3>
                        <p className="text-gray-400 mb-6">
                            {footerContent.description}
                        </p>
                        <div className="flex space-x-4">
                            {footerContent.social.map((social) => {
                                const Icon = SOCIAL_ICONS[social.platform.toLowerCase()];
                                if (!Icon) return null;

                                return (
                                    <a
                                        key={social.platform}
                                        href={social.href}
                                        className="text-gray-400 hover:text-white transition-colors"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Icon className="h-6 w-6" />
                                    </a>
                                );
                            })}
                        </div>
                    </div>

                    {/* Footer Sections */}
                    {footerContent.sections.map(section => (
                        <div key={section.title} className="col-span-1">
                            <h3 className="text-white font-semibold mb-4">
                                {section.title}
                            </h3>
                            <ul className="space-y-2">
                                {section.links.map(link => (
                                    <li key={link.label}>
                                        <a
                                            href={link.href}
                                            className="text-gray-400 hover:text-white transition-colors"
                                        >
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Contact Information */}
                <div className="mt-12 pt-8 border-t border-gray-800">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm text-gray-400">
                        <a
                            href={`mailto:${footerContent.contact.email}`}
                            className="flex items-center gap-2 hover:text-white transition-colors"
                        >
                            <Mail className="h-4 w-4" />
                            {footerContent.contact.email}
                        </a>
                        <a
                            href={`tel:${footerContent.contact.phone}`}
                            className="flex items-center gap-2 hover:text-white transition-colors"
                        >
                            <Phone className="h-4 w-4" />
                            {footerContent.contact.phone}
                        </a>
                        <div className="flex items-center gap-2">
                            <MapPin className="h-4 w-4" />
                            {footerContent.contact.location}
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
                    <p>© {currentYear} {businessName}. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;