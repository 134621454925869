// src/components/CMSView/index.js
import React, { useState } from 'react';
import TopNav from './TopNav';
import Sidebar from './Sidebar';
import Preview from './Preview';

const CMSView = ({
                     formData,
                     websiteGoal,
                     setWebsiteGoal,
                     taskProgress,
                     setTaskProgress,
                     previewContent: initialPreviewContent
                 }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [previewContent, setPreviewContent] = useState(initialPreviewContent);

    const handleContentUpdate = (newContent) => {
        console.log('Updating preview content:', newContent);
        setPreviewContent(prev => ({
            ...prev,
            ...newContent
        }));
    };

    return (
        <div className="fixed inset-0 bg-gray-50">
            <TopNav
                businessName={formData.businessName}
                sidebarOpen={sidebarOpen}
                onToggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            />

            <div className="flex h-[calc(100vh-4rem)] mt-16">
                <Preview
                    formData={formData}
                    previewContent={previewContent}
                />
                <Sidebar
                    isOpen={sidebarOpen}
                    websiteGoal={websiteGoal}
                    setWebsiteGoal={setWebsiteGoal}
                    taskProgress={taskProgress}
                    setTaskProgress={setTaskProgress}
                    formData={formData}
                    onContentUpdate={handleContentUpdate}
                />
            </div>
        </div>
    );
};

export default CMSView;