// src/WebsiteBuilder.jsx
import React, { useState, useEffect } from 'react';
import WelcomeIntroduction from './components/WelcomeIntroduction';
import InitialForm from './components/InitialForm';
import TemplateSelection from './components/TemplateSelection';
import CMSView from './components/CMSView';

const WebsiteBuilder = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentStep, setCurrentStep] = useState('welcome');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [websiteGoal, setWebsiteGoal] = useState('');
  const [taskProgress, setTaskProgress] = useState({
    content: false,
    images: false,
    pages: false
  });
  const [formData, setFormData] = useState({
    businessName: '',
    industry: '',
    description: '',
    audience: '',
    usp: ''
  });
  // Add state for generated content
  const [generatedContent, setGeneratedContent] = useState(null);

  useEffect(() => {
    const hasSeenWelcome = false; //localStorage.getItem('hasSeenWelcome');
    if (hasSeenWelcome) {
      setShowWelcome(false);
      setCurrentStep('info');
    }

    // Load any existing generated content
    const savedContent = localStorage.getItem('generatedContent');
    if (savedContent) {
      try {
        setGeneratedContent(JSON.parse(savedContent));
      } catch (error) {
        console.error('Error parsing saved content:', error);
      }
    }
  }, []);

  const handleWelcomeComplete = () => {
    localStorage.setItem('hasSeenWelcome', 'true');
    setShowWelcome(false);
    setCurrentStep('info');
  };

  const handleInitialFormComplete = (content) => {
    setGeneratedContent(content);
    setCurrentStep('templates');
  };

  return (
      <div className="h-screen">
        {showWelcome && (
            <WelcomeIntroduction onContinue={handleWelcomeComplete} />
        )}

        {currentStep === 'info' && (
            <InitialForm
                formData={formData}
                setFormData={setFormData}
                onNext={handleInitialFormComplete}
            />
        )}

        {currentStep === 'templates' && (
            <TemplateSelection
                formData={formData}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                onBack={() => setCurrentStep('info')}
                onNext={() => setCurrentStep('cms')}
                generatedContent={generatedContent}
            />
        )}

        {currentStep === 'cms' && (
            <CMSView
                formData={formData}
                websiteGoal={websiteGoal}
                setWebsiteGoal={setWebsiteGoal}
                taskProgress={taskProgress}
                setTaskProgress={setTaskProgress}
                previewContent={generatedContent}  // Pass the generated content here
            />
        )}
      </div>
  );
};

export default WebsiteBuilder;