import React, { useState } from 'react';
import { Layout, Sparkles, ShieldCheck, ArrowLeft, ArrowRight } from 'lucide-react';
import Button from '../../shared/Button';

const TemplateSelection = ({ formData, selectedTemplate, setSelectedTemplate, onBack, onNext }) => {
  const [hoveredTemplate, setHoveredTemplate] = useState(null);

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const handleContinue = () => {
    onNext();
  };

  const templates = [
    {
      id: 'modern',
      name: 'Modern & Minimalist',
      description: 'Clean and sophisticated design that lets your content shine',
      bestFor: 'Perfect for businesses focusing on premium experiences',
      preview: '/templates/modern-preview.jpg',
      features: [
        'Sleek, minimalist layout',
        'Large, impactful images',
        'Plenty of white space',
        'Focus on typography'
      ],
      style: {
        accent: 'blue',
        mood: 'Premium and sophisticated'
      }
    },
    {
      id: 'vibrant',
      name: 'Warm & Inviting',
      description: 'Engaging design that creates an immediate connection',
      bestFor: 'Ideal for businesses that want to feel approachable and friendly',
      preview: '/templates/vibrant-preview.jpg',
      features: [
        'Warm color palette',
        'Welcoming imagery',
        'Interactive elements',
        'Engaging layout'
      ],
      style: {
        accent: 'orange',
        mood: 'Welcoming and dynamic'
      }
    },
    {
      id: 'classic',
      name: 'Timeless & Professional',
      description: 'Traditional layout with a modern touch',
      bestFor: 'Great for established businesses that want to convey trust',
      preview: '/templates/classic-preview.jpg',
      features: [
        'Traditional structure',
        'Professional appearance',
        'Clear hierarchy',
        'Proven layout'
      ],
      style: {
        accent: 'green',
        mood: 'Traditional and trustworthy'
      }
    }
  ];

  return (
      <div className="fixed inset-0 flex flex-col bg-gray-50">
        {/* Fixed Header */}
        <div className="flex-shrink-0 bg-white border-b">
          <div className="px-6 py-4 max-w-6xl mx-auto">
            <div className="flex justify-between items-center">
              <button
                  onClick={onBack}
                  className="flex items-center text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="h-5 w-5 mr-2" />
                Back
              </button>
              <div className="text-sm text-gray-600">Step 2 of 3</div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="max-w-6xl mx-auto px-6 py-6">
            {/* Title Section */}
            <div className="text-center mb-6">
              <div className="flex items-center justify-center gap-2 mb-3">
                <Layout className="h-6 w-6 text-blue-600" />
                <h1 className="text-3xl font-bold text-gray-900">
                  Choose Your Website Style
                </h1>
              </div>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                We've created three unique designs for {formData.businessName}.
                Each version is fully customizable and will be populated with your content.
              </p>
            </div>

            {/* Templates Grid */}
            <div className="grid grid-cols-3 gap-6">
              {templates.map((template) => (
                  <div
                      key={template.id}
                      onClick={() => handleTemplateSelect(template.id)}
                      className={`
                  relative rounded-xl border-2 transition-all duration-200 cursor-pointer
                  ${selectedTemplate === template.id
                          ? 'border-blue-500 shadow-lg'
                          : 'border-gray-200 hover:border-blue-200'
                      }
                `}
                      onMouseEnter={() => setHoveredTemplate(template.id)}
                      onMouseLeave={() => setHoveredTemplate(null)}
                  >
                    {/* Preview Image */}
                    <div className="h-48 relative rounded-t-lg overflow-hidden">
                      <img
                          src="/api/placeholder/400/320"
                          alt={template.name}
                          className="w-full h-full object-cover"
                      />

                      {/* Hover Overlay */}
                      {hoveredTemplate === template.id && (
                          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                            <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTemplateSelect(template.id);
                                }}
                                variant="white"
                            >
                              Select This Design
                            </Button>
                          </div>
                      )}

                      {/* Selected Indicator */}
                      {selectedTemplate === template.id && (
                          <div className="absolute top-4 right-4">
                            <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                              Selected
                            </div>
                          </div>
                      )}
                    </div>

                    {/* Template Info */}
                    <div className="p-5">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {template.name}
                      </h3>
                      <p className="text-base text-gray-600 mb-4">
                        {template.description}
                      </p>

                      <div className="space-y-3">
                        {template.features.map((feature, index) => (
                            <div key={index} className="flex items-center gap-2 text-sm">
                              <Sparkles className="h-4 w-4 text-blue-500 flex-shrink-0" />
                              <span className="text-gray-700">{feature}</span>
                            </div>
                        ))}
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>

        {/* Fixed Footer */}
        <div className="flex-shrink-0 border-t bg-white">
          <div className="px-6 py-4 max-w-6xl mx-auto flex justify-between items-center">
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <ShieldCheck className="h-4 w-4" />
              All designs are mobile-friendly and customizable
            </div>
            <Button
                onClick={handleContinue}
                disabled={!selectedTemplate}
                className={`${!selectedTemplate ? 'opacity-50 cursor-not-allowed' : ''} h-10`}
            >
              Continue to Customization
              <ArrowRight className="h-4 w-4 ml-2" />
            </Button>
          </div>
        </div>
      </div>
  );
};

export default TemplateSelection;