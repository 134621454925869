import React from 'react';
import { ArrowLeft, ArrowRight, RefreshCw, Share2, MoreHorizontal } from 'lucide-react';

const BrowserBar = ({ url = 'www.website.com', className }) => {
    return (
        <div className={`bg-gray-100 border-b border-gray-200 rounded-t-lg overflow-hidden ${className}`}>
            {/* Traffic lights - Adjusted size and spacing */}
            <div className="flex items-center gap-2 px-3 py-1.5 md:py-2 border-b border-gray-200">
                <div className="h-2.5 w-2.5 md:h-3 md:w-3 rounded-full bg-red-400 transition-colors hover:bg-red-500"></div>
                <div className="h-2.5 w-2.5 md:h-3 md:w-3 rounded-full bg-yellow-400 transition-colors hover:bg-yellow-500"></div>
                <div className="h-2.5 w-2.5 md:h-3 md:w-3 rounded-full bg-green-400 transition-colors hover:bg-green-500"></div>
            </div>

            {/* Browser controls - Optimized spacing and touch targets */}
            <div className="flex items-center px-2 md:px-4 py-1.5 md:py-2">
                {/* Navigation buttons */}
                <div className="flex items-center gap-1 md:gap-2 text-gray-500">
                    <button className="p-1.5 hover:bg-gray-200 rounded-md transition-colors">
                        <ArrowLeft className="h-3.5 w-3.5 md:h-4 md:w-4" />
                    </button>
                    <button className="p-1.5 hover:bg-gray-200 rounded-md transition-colors">
                        <ArrowRight className="h-3.5 w-3.5 md:h-4 md:w-4" />
                    </button>
                    <button className="p-1.5 hover:bg-gray-200 rounded-md transition-colors">
                        <RefreshCw className="h-3.5 w-3.5 md:h-4 md:w-4" />
                    </button>
                </div>

                {/* URL bar - Improved responsiveness */}
                <div className="flex-1 mx-2 md:mx-4">
                    <div className="bg-white border border-gray-200 rounded-full px-2 md:px-3 py-1 flex items-center group hover:border-gray-300 transition-colors">
                        <span className="text-gray-400 flex-shrink-0">
                            <svg
                                className="h-3.5 w-3.5 md:h-4 md:w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 2a8 8 0 100 16 8 8 0 000-16zM4.5 10a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                        <span className="ml-1.5 md:ml-2 text-xs md:text-sm text-gray-600 truncate">
                            {url}
                        </span>
                    </div>
                </div>

                {/* Action buttons */}
                <div className="flex items-center gap-1 md:gap-2 text-gray-500">
                    <button className="p-1.5 hover:bg-gray-200 rounded-md transition-colors">
                        <Share2 className="h-3.5 w-3.5 md:h-4 md:w-4" />
                    </button>
                    <button className="p-1.5 hover:bg-gray-200 rounded-md transition-colors">
                        <MoreHorizontal className="h-3.5 w-3.5 md:h-4 md:w-4" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BrowserBar;