import React, { useState } from 'react';
import { FileText, ImagePlus, LayoutGrid, CalendarDays, Megaphone, Sparkles, ShieldCheck, ArrowLeft } from 'lucide-react';
import TaskCard from './TaskCard';
import ContentRefinementTask from './ContentRefinementTask';
import ImageUploadTask from './ImageUploadTask';
import SubpageCreationTask from './SubpageCreationTask';
import TransactionSetupTask from './TransactionSetupTask';
import MarketingSetupTask from './MarketingSetupTask';
import ComplianceTask from "./ComplianceTask";

const TaskSystem = ({ websiteGoal, formData, taskProgress, setTaskProgress, onContentUpdate }) => {
    const [activeTask, setActiveTask] = useState(null);

    const getTaskBenefits = (taskId) => {
        const benefits = {
            content: "Instantly update all content to match your style",
            images: "Your photos will replace all website images at once",
            pages: "Complete your website story in one step",
            compliance: "Get all required legal pages automatically set up for your location",
            transactions: "Make it easy for customers to do business with you",
            marketing: "Help new customers discover your business"
        };

        return benefits[taskId] || "";
    };

    const getTaskComponent = (taskId) => {
        const components = {
            content: ContentRefinementTask,
            images: ImageUploadTask,
            pages: SubpageCreationTask,
            transactions: TransactionSetupTask,
            marketing: MarketingSetupTask,
            compliance: ComplianceTask
        };
        return components[taskId];
    };

    const handleTaskClick = (taskId) => {
        setActiveTask(prev => (prev === taskId ? null : taskId));
    };

    const handleTaskComplete = (taskId) => {
        setTaskProgress(prev => ({ ...prev, [taskId]: true }));
        setActiveTask(null);
    };

    const TaskContent = activeTask ? getTaskComponent(activeTask) : null;

    return (
        <div className="h-full relative">
            {/* Task List */}
            <div className={`
                h-full
                overflow-y-auto
                transition-transform
                duration-300
                ease-in-out
                ${activeTask ? 'translate-x-full' : 'translate-x-0'}
            `}>
                <div className="p-6 space-y-6">
                    {/* Introduction */}
                    <div className="space-y-4">
                        <div>
                            <h2 className="text-2xl font-semibold text-gray-900">
                                Make this website truly yours!
                            </h2>
                            <p className="text-gray-600 mt-2">
                                Your professional website is ready for you finishing touches. Choose from the actions below to customize everything and start bringing in customers.
                            </p>
                        </div>

                        <div className="bg-blue-50 rounded-lg p-4">
                            <div className="flex gap-2">
                                <Sparkles className="h-5 w-5 text-blue-600 flex-shrink-0 mt-1" />
                                <div>
                                    <p className="text-blue-800">
                                        Each change you make will update your whole website preview in real-time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Express Your Identity Phase */}
                    <div>
                        <h2 className="text-xs font-medium text-gray-500 mb-4 uppercase tracking-wider">
                            Personalize Everything
                        </h2>
                        <div className="space-y-4">
                            <TaskCard
                                id="content"
                                title="Improve your website text"
                                description="Personalize all website text in one step"
                                benefit={getTaskBenefits('content')}
                                icon={Sparkles}
                                isComplete={taskProgress.content}
                                onClick={() => handleTaskClick('content')}
                            />
                            <TaskCard
                                id="images"
                                title="Choose your images"
                                description="Update all website photos at once to showcase your work"
                                benefit={getTaskBenefits('images')}
                                icon={ImagePlus}
                                isComplete={taskProgress.images}
                                onClick={() => handleTaskClick('images')}
                            />
                            <TaskCard
                                id="pages"
                                title="Tell Your Story"
                                description="Add all the pages your business needs in one go"
                                benefit={getTaskBenefits('pages')}
                                icon={LayoutGrid}
                                isComplete={taskProgress.pages}
                                onClick={() => handleTaskClick('pages')}
                            />
                        </div>
                    </div>

                    <div>
                        <h2 className="text-xs font-medium text-gray-500 mb-4 uppercase tracking-wider">
                            Grow Your Business
                        </h2>
                        <div className="space-y-4">
                            <TaskCard
                                id="transactions"
                                title="Get in Touch with Customers"
                                description="Make it easy for customers to do business with you"
                                benefit={getTaskBenefits('transactions')}
                                icon={CalendarDays}
                                isComplete={taskProgress.transactions}
                                onClick={() => handleTaskClick('transactions')}
                            />
                            <TaskCard
                                id="marketing"
                                title="Reach New Customers"
                                description="Set up tools to help customers find you online"
                                benefit={getTaskBenefits('marketing')}
                                icon={Megaphone}
                                isComplete={taskProgress.marketing}
                                onClick={() => handleTaskClick('marketing')}
                            />
                        </div>
                    </div>

                    {/* Compliance Phase */}
                    <div>
                        <h2 className="text-xs font-medium text-gray-500 mb-4 uppercase tracking-wider">
                            Stay Protected
                        </h2>
                        <div>
                            <TaskCard
                                id="compliance"
                                title="Protect Your Business"
                                description="Get all legal pages set up for your region"
                                benefit={getTaskBenefits('compliance')}
                                icon={ShieldCheck}
                                isComplete={taskProgress.compliance}
                                onClick={() => handleTaskClick('compliance')}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Task Detail View */}
            {activeTask && TaskContent && (
                <div className="absolute inset-0 bg-white flex flex-col">
                    <div className="flex-shrink-0 p-6 border-b">
                        <button
                            onClick={() => setActiveTask(null)}
                            className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
                        >
                            <ArrowLeft className="h-5 w-5 mr-2" />
                            Back to Tasks
                        </button>
                    </div>
                    <div className="flex-1 overflow-y-auto">
                        <TaskContent
                            websiteGoal={websiteGoal}
                            formData={formData}
                            onComplete={() => handleTaskComplete(activeTask)}
                            onContentGenerated={onContentUpdate}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TaskSystem;