// src/components/TaskSystem/TransactionSetupTask.jsx
import React, { useState } from 'react';
import { FormInput, Check, Info, Sparkles, Loader2 } from 'lucide-react';
import Button from "../../../shared/Button";
import { generateFormSuggestions } from '../../../services/langchain';

const TransactionSetupTask = ({ websiteGoal, formData, onComplete, onContentGenerated }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [suggestions, setSuggestions] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);

    // Load suggestions when component mounts
    React.useEffect(() => {
        loadSuggestions();
    }, []);

    const loadSuggestions = async () => {
        setIsLoading(true);
        try {
            const generated = await generateFormSuggestions(formData, websiteGoal);
            setSuggestions(generated);
        } catch (err) {
            console.error('Error generating suggestions:', err);
            setError('Failed to generate form suggestions. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSelect = async (form) => {
        setSelectedForm(form);

        // Get existing content
        const existingContent = JSON.parse(localStorage.getItem('generatedContent') || '{}');

        // Update content with new form
        const updatedContent = {
            ...existingContent,
            form: {
                ...form,
                isNew: true // Flag for animation
            }
        };

        // Store updated content
        localStorage.setItem('generatedContent', JSON.stringify(updatedContent));

        // Update preview
        if (onContentGenerated) {
            onContentGenerated(updatedContent);
        }

        // Mark task as complete
        onComplete();
    };

    if (isLoading) {
        return (
            <div className="p-8 text-center">
                <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4 text-blue-600" />
                <h3 className="font-medium text-lg mb-2">Generating Form Suggestions</h3>
                <p className="text-gray-600">
                    AI is analyzing your business to suggest the most effective form options...
                </p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-8">
                <div className="bg-red-50 p-4 rounded-lg text-red-600 mb-4">
                    {error}
                </div>
                <Button onClick={loadSuggestions}>Try Again</Button>
            </div>
        );
    }

    return (
        <div className="p-4 space-y-6">
            {/* Context Banner */}
            <div className="bg-blue-50 p-4 rounded-lg space-y-2">
                <div className="flex items-center gap-2 text-blue-800 font-medium">
                    <Sparkles className="h-5 w-5 text-blue-600" />
                    AI-Recommended Forms
                </div>
                <p className="text-sm text-blue-700">
                    Based on your business type and goals, we recommend the following form options:
                </p>
            </div>

            {/* Form Options */}
            <div className="space-y-4">
                {suggestions?.forms.map((form, index) => (
                    <div
                        key={index}
                        className={`
                            border rounded-lg transition-all duration-300
                            ${selectedForm?.id === form.id ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-300'}
                        `}
                    >
                        <div className="p-4">
                            <div className="flex items-start gap-3 mb-3">
                                <FormInput className="h-5 w-5 text-blue-600 mt-1" />
                                <div>
                                    <h3 className="font-medium">{form.title}</h3>
                                    <p className="text-sm text-gray-600 mt-1">{form.description}</p>
                                </div>
                            </div>

                            {/* Benefits */}
                            <div className="ml-8 space-y-2">
                                {form.benefits.map((benefit, idx) => (
                                    <div key={idx} className="flex items-start gap-2 text-sm">
                                        <Check className="h-4 w-4 text-green-500 mt-0.5" />
                                        <span>{benefit}</span>
                                    </div>
                                ))}
                            </div>

                            {/* Field Preview */}
                            {selectedForm?.id === form.id && (
                                <div className="mt-4 bg-white rounded-lg border p-3">
                                    <h4 className="text-sm font-medium text-gray-700 mb-2">Form Fields</h4>
                                    <div className="space-y-2">
                                        {form.fields.map((field, idx) => (
                                            <div key={idx} className="flex items-center gap-2 text-sm text-gray-600">
                                                <div className="h-2 w-2 bg-blue-600 rounded-full"></div>
                                                <span>{field}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Action Button */}
                            <Button
                                variant={selectedForm?.id === form.id ? "default" : "outline"}
                                className="w-full mt-4"
                                onClick={() => handleFormSelect(form)}
                            >
                                {selectedForm?.id === form.id ? 'Selected' : 'Select This Form'}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Info Note */}
            <div className="flex items-center gap-2 text-sm text-gray-500">
                <Info className="h-4 w-4" />
                <span>The form will be added to your website and can be customized later</span>
            </div>
        </div>
    );
};

export default TransactionSetupTask;