import React from 'react';
import { Menu, MessageSquare, Upload } from 'lucide-react';
import Button from '../../shared/Button';

const TopNav = ({ sidebarOpen, onToggleSidebar }) => {
    return (
        <div className="h-16 bg-white border-b flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-50">
            <button
                onClick={onToggleSidebar}
                className="p-2 hover:bg-gray-100 rounded-lg"
            >
                <Menu className="h-5 w-5" />
            </button>

            <div className="flex items-center gap-2">
                <span className="font-semibold text-xl">Jimdo</span>
            </div>

            <div className="flex items-center gap-2">
                <button className="p-2 hover:bg-gray-100 rounded-lg" title="AI Assistant">
                    <MessageSquare className="h-5 w-5" />
                </button>
                <Button icon={Upload}>Publish</Button>
            </div>
        </div>
    );
};

export default TopNav;