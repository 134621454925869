// src/services/unsplash.js
const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

export async function searchUnsplashImages(query, count = 10) {
    try {
        const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${encodeURIComponent(query)}&per_page=${count}&orientation=landscape`,
            {
                headers: {
                    'Authorization': `Client-ID ${UNSPLASH_ACCESS_KEY}`
                }
            }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch images');
        }

        const data = await response.json();
        return data.results.map(photo => ({
            url: photo.urls.regular,
            alt: photo.alt_description || photo.description || query,
            credit: {
                name: photo.user.name,
                link: photo.user.links.html
            }
        }));
    } catch (error) {
        console.error('Error fetching Unsplash images:', error);
        throw error;
    }
}