import React, { useState } from 'react';
import {
    Search,
    MapPin,
    Globe,
    Instagram,
    Sparkles,
    Info,
    CheckCircle2,
    ArrowRight,
    Loader2,
    Clock,
    TrendingUp
} from 'lucide-react';
import Button from '../../../shared/Button';

const MarketingSetupTask = ({ websiteGoal = 'brand', formData, onComplete }) => {
    const [selectedSource, setSelectedSource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleMarketingSetup = async (sourceId) => {
        setIsLoading(true);
        setError(null);

        try {
            // Simulate setup process
            await new Promise(resolve => setTimeout(resolve, 2000));
            onComplete();
        } catch (err) {
            console.error('Error in marketing setup:', err);
            setError('Failed to set up marketing. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSourceSelect = async (sourceId) => {
        setSelectedSource(sourceId);
        await handleMarketingSetup(sourceId);
    };

    // Get recommendation based on business type and goal
    const getRecommendedSource = () => {
        switch (websiteGoal) {
            case 'events':
                return 'google-ads';
            case 'classes':
                return 'social';
            case 'brand':
            default:
                return 'business-listing';
        }
    };

    const recommendedSource = getRecommendedSource();

    let marketingSources = [
        {
            id: 'business-listing',
            title: 'Business Listing & Local SEO',
            icon: MapPin,
            description: 'Get found by local customers searching for businesses like yours',
            isRecommended: recommendedSource === 'business-listing',
            benefits: [
                'Verified Google Business Profile setup',
                'Local directory submissions',
                'Review management system',
                'Local SEO optimization'
            ],
            requirements: [
                'Business address',
                'Contact information',
                'Business hours'
            ],
            metrics: [
                '92% of customers read online reviews',
                'Verified listings get 7x more clicks',
                'Average 150+ local searches per month'
            ],
            timeEstimate: 'Setup in 15 minutes'
        },
        {
            id: 'google-ads',
            title: 'Google Ads Campaign',
            icon: Search,
            description: 'Reach customers actively searching for your services',
            isRecommended: recommendedSource === 'google-ads',
            benefits: [
                'Target ready-to-book customers',
                'Location-based targeting',
                'AI-powered keyword optimization',
                'Automatic bid management'
            ],
            requirements: [
                'Google Ads account',
                'Marketing budget',
                'Business website'
            ],
            metrics: [
                'Average 4.3x return on ad spend',
                '73% of bookings start with search',
                'Targeted reach of 10,000+ monthly'
            ],
            timeEstimate: 'Ready in 20 minutes'
        },
        {
            id: 'social',
            title: 'Social Media Marketing',
            icon: Instagram,
            description: 'Promote your business on Instagram & Facebook',
            isRecommended: recommendedSource === 'social',
            benefits: [
                'Automated content scheduling',
                'Audience targeting',
                'Visual showcase of your work',
                'Engagement automation'
            ],
            requirements: [
                'Business social accounts',
                'Regular photo content',
                'Marketing budget'
            ],
            metrics: [
                '78% of bookings from social media',
                'Average 3.8x return on ad spend',
                'Reach 10,000+ local followers'
            ],
            timeEstimate: 'Setup in 25 minutes'
        },
        {
            id: 'seo',
            title: 'Advanced SEO Setup',
            icon: Globe,
            description: 'Optimize your website for search engines',
            isRecommended: false,
            compact: true,
            timeEstimate: 'Ready in 30 minutes'
        }
    ];

    // Reorder sources to put recommended first
    marketingSources = [
        ...marketingSources.filter(source => source.isRecommended),
        ...marketingSources.filter(source => !source.isRecommended)
    ];

    if (isLoading) {
        return (
            <div className="p-6">
                <div className="text-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto mb-4"></div>
                    <h3 className="font-medium text-gray-900 mb-1">
                        Setting Up Your Marketing...
                    </h3>
                    <p className="text-sm text-gray-500">
                        We're configuring your marketing tools for maximum impact
                    </p>
                </div>

                <div className="mt-8 space-y-2">
                    <div className="flex justify-between text-sm">
                        <span className="font-medium">Progress</span>
                        <span>Processing...</span>
                    </div>
                    <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div className="h-full bg-blue-600 animate-pulse rounded-full w-3/4" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="p-6 space-y-6">
            {/* Context Banner */}
            <div className="bg-blue-50 p-4 rounded-lg space-y-2">
                <div className="flex items-center gap-2 text-blue-800 font-medium">
                    <TrendingUp className="h-5 w-5 text-blue-600" />
                    Start Growing Your Business Today
                </div>
                <p className="text-sm text-blue-700">
                    Choose the right marketing tools to attract customers and grow your business.
                    Our AI will help set everything up for optimal performance.
                </p>
            </div>

            {/* Marketing Sources */}
            <div className="space-y-4">
                {marketingSources.map((source) => (
                    <div
                        key={source.id}
                        className={`
                            border rounded-lg overflow-hidden transition-all
                            ${selectedSource === source.id ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-300'}
                            ${source.isRecommended ? 'border-blue-200 bg-blue-50/50' : ''}
                        `}
                    >
                        <div className="p-4">
                            <div className="flex items-start gap-3">
                                <source.icon className={`h-5 w-5 mt-1 ${source.isRecommended ? 'text-blue-600' : 'text-gray-600'}`} />
                                <div className="flex-1">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                            <h3 className="font-medium text-gray-900">{source.title}</h3>
                                            {source.isRecommended && (
                                                <span className="text-xs bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                                                    Recommended
                                                </span>
                                            )}
                                        </div>
                                        <span className="text-sm text-gray-500 flex items-center gap-1">
                                            <Clock className="h-3 w-3" />
                                            {source.timeEstimate}
                                        </span>
                                    </div>
                                    <p className="text-sm text-gray-600 mt-1">{source.description}</p>

                                    {/* Detailed benefits for recommended option */}
                                    {source.isRecommended && !source.compact && (
                                        <div className="mt-4 space-y-4">
                                            {/* Benefits */}
                                            <div className="space-y-2">
                                                {source.benefits?.map((benefit, idx) => (
                                                    <div key={idx} className="flex items-start gap-2 text-sm">
                                                        <CheckCircle2 className="h-4 w-4 text-green-500 mt-0.5" />
                                                        <span>{benefit}</span>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Requirements & Metrics */}
                                            <div className="grid md:grid-cols-2 gap-3">
                                                <div className="bg-white rounded-lg p-3">
                                                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                                                        Requirements:
                                                    </h4>
                                                    <ul className="space-y-1">
                                                        {source.requirements?.map((req, idx) => (
                                                            <li key={idx} className="text-sm text-gray-600 flex items-center gap-2">
                                                                <span className="h-1 w-1 bg-gray-400 rounded-full"></span>
                                                                {req}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="bg-white rounded-lg p-3">
                                                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                                                        Expected Results:
                                                    </h4>
                                                    <ul className="space-y-1">
                                                        {source.metrics?.map((metric, idx) => (
                                                            <li key={idx} className="text-sm text-gray-600 flex items-center gap-2">
                                                                <span className="h-1 w-1 bg-gray-400 rounded-full"></span>
                                                                {metric}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Button
                                className="w-full mt-4"
                                variant={selectedSource === source.id ? 'default' : 'outline'}
                                onClick={() => handleSourceSelect(source.id)}
                                disabled={isLoading}
                            >
                                {isLoading && selectedSource === source.id ? (
                                    <>
                                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                                        Setting Up Marketing...
                                    </>
                                ) : (
                                    <>
                                        {source.isRecommended ? 'Start Setup' : 'Select'}
                                        <ArrowRight className="h-4 w-4 ml-2" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            {error && (
                <div className="bg-red-50 text-red-600 p-3 rounded-lg text-sm">
                    {error}
                </div>
            )}

            {/* Helper text */}
            <div className="text-sm text-gray-500 flex items-center gap-2">
                <Info className="h-4 w-4" />
                <span>You can adjust your marketing settings at any time</span>
            </div>
        </div>
    );
};

export default MarketingSetupTask;