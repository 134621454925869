// src/components/CMSView/Preview/FormSection.jsx
import React, { useEffect, useState } from 'react';
import Button from "../../../shared/Button";

const FormSection = ({ form, isNew = false }) => {
    const [showAnimation, setShowAnimation] = useState(isNew);

    useEffect(() => {
        if (isNew) {
            setShowAnimation(true);
            const timer = setTimeout(() => {
                setShowAnimation(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isNew]);

    if (!form) return null;

    return (
        <div
            className={`
                bg-white py-16 border-t
                transition-all duration-500 ease-out
                ${showAnimation ? 'animate-slide-in-bottom' : ''}
            `}
        >
            <div className="max-w-2xl mx-auto px-8">
                <h2 className="text-3xl font-bold mb-8 text-center">{form.formTitle}</h2>
                <p className="text-gray-600 text-center mb-12">{form.formDescription}</p>

                <form
                    className="space-y-6"
                    onSubmit={(e) => e.preventDefault()}
                >
                    {form.fields.map((field, index) => (
                        <div key={index} className="space-y-1">
                            <label className="block text-sm font-medium text-gray-700">
                                {field}
                            </label>
                            <input
                                type="text"
                                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                placeholder={`Enter your ${field.toLowerCase()}`}
                            />
                        </div>
                    ))}

                    <Button
                        type="submit"
                        className="w-full mt-8"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default FormSection;