// src/components/TaskSystem/ContentRefinementTask.jsx
import React, { useState } from 'react';
import { MessageSquare, Sparkles, Loader2 } from 'lucide-react';
import Button from '../../../shared/Button';
import { refineWebsiteContent } from '../../../services/langchain';

const ContentRefinementTask = ({
                                   formData,
                                   websiteGoal,
                                   onComplete,
                                   onContentGenerated // Add this prop
                               }) => {
    const [step, setStep] = useState('audience');
    const [taskData, setTaskData] = useState({
        audience: '',
        usp: '',
        additionalInfo: ''
    });
    const [isRefining, setIsRefining] = useState(false);
    const [error, setError] = useState(null);

    const steps = {
        audience: {
            title: "Who's Your Target Audience?",
            description: websiteGoal === 'classes'
                ? "Help us understand who would be interested in your cooking classes. This helps us write content that appeals to them."
                : "Tell us about your ideal guests. This helps us tailor the content to attract the right audience.",
            placeholder: websiteGoal === 'classes'
                ? "E.g., Cooking enthusiasts, corporate teams looking for team building activities..."
                : "E.g., Food lovers seeking unique dining experiences, corporate event planners..."
        },
        usp: {
            title: "What Makes Your Experience Unique?",
            description: "What sets you apart from others? This helps us highlight your unique value.",
            placeholder: "E.g., Small class sizes, unique locations, specialized cuisine..."
        },
        additionalInfo: {
            title: "Any Additional Details?",
            description: "Share anything else that could help us create better content for you.",
            placeholder: "E.g., Special techniques, signature dishes, awards..."
        }
    };

    const getProgressPercentage = () => {
        const currentIndex = Object.keys(steps).indexOf(step);
        return ((currentIndex + 1) / Object.keys(steps).length) * 100;
    };

    const handleNext = async () => {
        if (step === 'additionalInfo') {
            setIsRefining(true);
            setError(null);

            try {
                // Get existing content from localStorage
                const existingContent = JSON.parse(localStorage.getItem('generatedContent')) || {};

                // Generate refined content
                const refinedContent = await refineWebsiteContent(
                    formData,
                    websiteGoal,
                    taskData,
                    existingContent
                );

                // Update localStorage
                localStorage.setItem('generatedContent', JSON.stringify(refinedContent));

                // Update preview
                onContentGenerated(refinedContent);

                // Complete the task
                onComplete();

            } catch (err) {
                console.error('Error refining content:', err);
                setError('Failed to refine content. Please try again.');
            } finally {
                setIsRefining(false);
            }
        } else {
            setStep(step === 'audience' ? 'usp' : 'additionalInfo');
        }
    };

    const handleBack = () => {
        setStep(step === 'usp' ? 'audience' : 'usp');
    };

    const currentStep = steps[step];

    return (
        <div className="p-4 space-y-6">
            {/* Progress Bar */}
            <div className="space-y-1">
                <div className="flex justify-between text-sm">
                    <span className="font-medium">Enhancing your content</span>
                    <span className="text-gray-500">{Math.round(getProgressPercentage())}%</span>
                </div>
                <div className="h-1.5 bg-gray-100 rounded-full">
                    <div
                        className="h-full bg-blue-600 rounded-full transition-all duration-300"
                        style={{ width: `${getProgressPercentage()}%` }}
                    />
                </div>
            </div>

            {/* Current Step */}
            <div className="space-y-4">
                <div>
                    <h3 className="text-lg font-medium">{currentStep.title}</h3>
                    <p className="text-gray-600 mt-1">{currentStep.description}</p>
                </div>

                <textarea
                    value={taskData[step]}
                    onChange={(e) => setTaskData(prev => ({ ...prev, [step]: e.target.value }))}
                    placeholder={currentStep.placeholder}
                    className="w-full p-3 border rounded-lg h-32 resize-none"
                />

                {taskData[step].length > 0 && (
                    <div className="bg-blue-50 p-4 rounded-lg flex items-start gap-3">
                        <Sparkles className="h-5 w-5 text-blue-600 mt-0.5" />
                        <p className="text-sm text-blue-800">
                            Great details! This will help us create more engaging content that resonates with your audience.
                        </p>
                    </div>
                )}

                {error && (
                    <div className="bg-red-50 p-4 rounded-lg text-red-600 text-sm">
                        {error}
                    </div>
                )}
            </div>

            {/* Navigation */}
            <div className="flex justify-between">
                <Button
                    variant="secondary"
                    onClick={handleBack}
                    disabled={step === 'audience' || isRefining}
                >
                    Back
                </Button>

                <Button
                    onClick={handleNext}
                    disabled={!taskData[step] || isRefining}
                >
                    {isRefining ? (
                        <>
                            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                            Refining Content...
                        </>
                    ) : step === 'additionalInfo' ? (
                        <>
                            <Sparkles className="h-4 w-4 mr-2" />
                            Enhance Content
                        </>
                    ) : (
                        'Continue'
                    )}
                </Button>
            </div>

            {/* Context Hint */}
            {step === 'additionalInfo' && (
                <div className="mt-4 flex items-center gap-2 text-sm text-gray-500">
                    <MessageSquare className="h-4 w-4" />
                    <span>AI will use all provided information to enhance your website content</span>
                </div>
            )}
        </div>
    );
};

export default ContentRefinementTask;