import React, { useState } from 'react';
import {
    ShieldCheck,
    FileCheck,
    RefreshCcw,
    ArrowRight,
    AlertCircle,
    Loader2,
    CheckCircle2,
    Info,
    Building,
    LucideFileCheck
} from 'lucide-react';
import Button from '../../../shared/Button';

const ComplianceTask = ({ websiteGoal = 'brand', formData, onComplete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSetupCompliance = async () => {
        setIsLoading(true);
        setError(null);

        try {
            // Simulate setup process
            await new Promise(resolve => setTimeout(resolve, 2000));
            onComplete();
        } catch (err) {
            console.error('Error setting up compliance:', err);
            setError('Failed to setup compliance. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Get documents based on business type
    const getLegalDocuments = () => {
        if (websiteGoal === 'shop') {
            return [
                'Privacy Policy',
                'Imprint',
                'Return Policy',
                'Terms and Conditions',
                'Cookie Banner',
                '3rd Party Integrations'
            ];
        }
        return [
            'Privacy Policy',
            'Imprint',
            'Cookie Banner',
            '3rd Party Integrations'
        ];
    };

    const features = [
        {
            icon: FileCheck,
            title: 'GDPR-Compliant Legal Texts',
            description: 'Generated by Trusted Shops with over 500,000 successful implementations'
        },
        {
            icon: RefreshCcw,
            title: 'Automatic Updates',
            description: 'Your legal texts stay current with regulation changes and website features'
        },
        {
            icon: Building,
            title: 'Business-Specific Content',
            description: 'Customized for your business type and integrations'
        }
    ];

    return (
        <div className="p-6 space-y-6">
            {/* Main Context */}
            <div className="bg-green-50 p-4 rounded-lg space-y-3">
                <div className="flex items-center gap-2 text-green-800 font-medium">
                    <ShieldCheck className="h-5 w-5 text-green-600" />
                    Guaranteed Legal Compliance
                </div>
                <p className="text-sm text-green-700">
                    Stop worrying about GDPR compliance. Our Legal Text Generator creates and maintains
                    all required legal documents, guaranteed by Trusted Shops to be fully compliant.
                </p>
            </div>

            {/* Generated Documents */}
            <div className="bg-white border rounded-lg p-4 space-y-4">
                <div className="flex items-center gap-2 mb-2">
                    <LucideFileCheck className="h-5 w-5 text-gray-600" />
                    <h3 className="font-medium text-gray-900">Legal Documents We'll Generate</h3>
                </div>
                <div className="grid gap-2">
                    {getLegalDocuments().map((document, index) => (
                        <div key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <CheckCircle2 className="h-4 w-4 text-green-500 flex-shrink-0" />
                            <span>{document}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Key Features */}
            <div className="space-y-4">
                <h3 className="font-medium text-gray-900">Smart Legal Protection</h3>
                <div className="grid gap-4">
                    {features.map((feature, index) => (
                        <div key={index} className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg">
                            <feature.icon className="h-5 w-5 text-blue-600 mt-0.5" />
                            <div>
                                <h4 className="font-medium text-gray-900">{feature.title}</h4>
                                <p className="text-sm text-gray-600">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* How it Works */}
            <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium text-gray-900 mb-3">How it Works</h3>
                <div className="space-y-3">
                    <div className="flex gap-2">
                        <span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">1</span>
                        <p className="text-sm text-gray-600">Answer a few simple questions about your business</p>
                    </div>
                    <div className="flex gap-2">
                        <span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">2</span>
                        <p className="text-sm text-gray-600">Legal texts are automatically generated and added to your website</p>
                    </div>
                    <div className="flex gap-2">
                        <span className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">3</span>
                        <p className="text-sm text-gray-600">Documents are automatically updated when regulations change</p>
                    </div>
                </div>
            </div>

            {/* Action Button */}
            <Button
                onClick={handleSetupCompliance}
                disabled={isLoading}
                className="w-full"
            >
                {isLoading ? (
                    <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        Generating Legal Documents...
                    </>
                ) : (
                    <>
                        Start Legal Text Generator
                        <ArrowRight className="h-4 w-4 ml-2" />
                    </>
                )}
            </Button>

            {error && (
                <div className="bg-red-50 text-red-600 p-3 rounded-lg text-sm">
                    {error}
                </div>
            )}

            {/* Helper Text */}
            <div className="text-sm text-gray-500 flex items-center gap-2">
                <Info className="h-4 w-4" />
                <span>Your texts will automatically update when you add new features like Facebook or Google Maps</span>
            </div>
        </div>
    );
};

export default ComplianceTask;