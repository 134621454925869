import React, { useState } from 'react';
import { Sparkles, LayoutGrid, Info } from 'lucide-react';
import Button from '../../../shared/Button';

const SubpageCreationTask = ({ websiteGoal = 'brand', onComplete }) => {
    const [selectedPages, setSelectedPages] = useState([]);

    // Goal-specific configurations
    const goalConfigs = {
        events: {
            title: "Event-Focused Structure",
            description: "We've curated pages that optimize for event discovery and booking, making it easy for potential guests to find and book your experiences.",
            pages: [
                {
                    title: 'Upcoming Events',
                    description: 'Showcase your upcoming pop-up events and dining experiences',
                    benefits: [
                        'Dynamic calendar of events',
                        'Detailed event descriptions',
                        'Easy booking integration'
                    ],
                    recommended: true
                },
                {
                    title: 'Private Events',
                    description: 'Promote your private dining and custom event services',
                    benefits: [
                        'Custom event inquiry form',
                        'Package descriptions',
                        'Past private event gallery'
                    ],
                    recommended: true
                }
            ]
        },
        classes: {
            title: "Class-Optimized Layout",
            description: "These pages are designed to showcase your cooking classes and make registration seamless for potential students.",
            pages: [
                {
                    title: 'Class Schedule',
                    description: 'List upcoming cooking classes and workshops',
                    benefits: [
                        'Real-time availability',
                        'Skill level indicators',
                        'Class size information'
                    ],
                    recommended: true
                },
                {
                    title: 'Class Details',
                    description: 'Detailed information about each class offering',
                    benefits: [
                        'Course curriculum',
                        'Prerequisites and materials',
                        'Instructor information'
                    ],
                    recommended: true
                }
            ]
        },
        brand: {
            title: "Brand-Building Pages",
            description: "This structure helps tell your story and establish your culinary brand identity.",
            pages: [
                {
                    title: 'Our Story',
                    description: 'Share your culinary journey and brand story',
                    benefits: [
                        'Build emotional connection',
                        'Showcase your expertise',
                        'Highlight unique value proposition'
                    ],
                    recommended: true
                },
                {
                    title: 'Gallery',
                    description: 'Visual showcase of your work and achievements',
                    benefits: [
                        'Professional portfolio',
                        'Visual storytelling',
                        'Social proof'
                    ],
                    recommended: true
                }
            ]
        }
    };

    // Common pages for all goals
    const commonPages = [
        {
            title: 'About Us',
            description: 'Share your culinary journey and expertise',
            benefits: [
                'Build trust with potential customers',
                'Showcase your expertise and background',
                'Tell your unique story'
            ]
        },
        {
            title: 'Contact',
            description: 'Make it easy for customers to reach you',
            benefits: [
                'Provide multiple contact options',
                'Include location and hours',
                'Streamline inquiry process'
            ]
        }
    ];

    // Get current goal configuration
    const currentConfig = goalConfigs[websiteGoal] || goalConfigs.brand;
    const allPages = [...commonPages, ...(currentConfig.pages || [])];

    return (
        <div className="p-4 space-y-6">
            {/* Goal Context */}
            <div className="bg-blue-50 p-4 rounded-lg space-y-2">
                <div className="flex items-center gap-2 text-blue-800 font-medium">
                    <Sparkles className="h-5 w-5 text-blue-600" />
                    {currentConfig.title}
                </div>
                <p className="text-sm text-blue-700">
                    {currentConfig.description}
                </p>
            </div>

            {/* Page Selection */}
            <div className="space-y-4">
                {allPages.map((page, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden">
                        <label className="flex items-start p-4 cursor-pointer">
                            <input
                                type="checkbox"
                                checked={selectedPages.includes(page.title)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedPages([...selectedPages, page.title]);
                                    } else {
                                        setSelectedPages(selectedPages.filter(p => p !== page.title));
                                    }
                                }}
                                className="mt-1 rounded text-blue-600"
                            />
                            <div className="ml-3 flex-1">
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">{page.title}</span>
                                    {page.recommended && (
                                        <span className="text-xs bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                                            Recommended
                                        </span>
                                    )}
                                </div>
                                <p className="text-sm text-gray-600 mt-1">{page.description}</p>

                                {/* Benefits */}
                                <div className="mt-3 space-y-2">
                                    {page.benefits.map((benefit, i) => (
                                        <div key={i} className="flex items-start gap-2 text-sm text-gray-600">
                                            <span className="text-blue-600 mt-1">•</span>
                                            <span>{benefit}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </label>
                    </div>
                ))}
            </div>

            {/* Action */}
            <Button
                onClick={onComplete}
                disabled={selectedPages.length === 0}
                className="w-full"
            >
                <LayoutGrid className="h-4 w-4 mr-2" />
                Create Selected Pages
            </Button>

            <div className="text-sm text-gray-500 flex items-center gap-2">
                <Info className="h-4 w-4" />
                <span>You can customize page content after creation</span>
            </div>
        </div>
    );
};

export default SubpageCreationTask;