// src/components/CMSView/Preview/HeroSection.jsx
import React from 'react';
import { ArrowRight } from 'lucide-react';

const HeroSection = ({ businessName, subtitle, image }) => {
    return (
        <div className="relative">
            {/* Background image with overlay */}
            <div className="relative h-[50vh] min-h-[600px]">
                <img
                    src={image?.url || '/default-hero.jpg'}
                    alt={image?.alt || businessName}
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50" />

                {/* Content */}
                <div className="relative h-full max-w-7xl mx-auto px-8 flex items-center">
                    <div className="max-w-2xl">
                        <h1 className="text-5xl lg:text-6xl font-bold text-white mb-6 leading-tight">
                            {businessName}
                        </h1>
                        <p className="text-xl lg:text-2xl text-gray-200 mb-8 leading-relaxed">
                            {subtitle}
                        </p>
                        <div className="flex flex-wrap gap-4">
                            <button className="bg-white text-gray-900 px-8 py-3 rounded-full font-medium hover:bg-gray-100 transition-colors flex items-center gap-2">
                                Book Now
                                <ArrowRight className="h-4 w-4" />
                            </button>
                            <button className="border-2 border-white text-white px-8 py-3 rounded-full font-medium hover:bg-white/10 transition-colors">
                                Learn More
                            </button>
                        </div>
                    </div>
                </div>

                {/* Image credit */}
                {image?.credit && (
                    <a
                        href={image.credit.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="absolute bottom-2 right-2 text-xs text-white/70 hover:text-white"
                    >
                        Photo by {image.credit.name}
                    </a>
                )}
            </div>

            {/* Optional decorative element */}
            <div className="absolute bottom-0 left-0 right-0 h-24" />
        </div>
    );
};

export default HeroSection;