import { ChatOpenAI } from "@langchain/openai";
import { PromptTemplate } from "@langchain/core/prompts";
import { StructuredOutputParser } from "langchain/output_parsers";
import { z } from "zod";
import { searchUnsplashImages } from './unsplash';

async function generateContentWithLangChain(formData, websiteGoal) {
    try {
        // Define the schema using zod
        const parser = StructuredOutputParser.fromZodSchema(
            z.object({
                hero: z.object({
                    title: z.string(),
                    subtitle: z.string()
                }),
                navigation: z.object({
                    links: z.array(
                        z.object({
                            label: z.string(),
                            href: z.string()
                        })
                    ).length(4),
                    cta: z.object({
                        label: z.string(),
                        href: z.string()
                    })
                }),
                servicesTitle: z.string(),
                services: z.array(
                    z.object({
                        title: z.string(),
                        description: z.string()
                    })
                ).length(3),
                events: z.array(
                    z.object({
                        title: z.string(),
                        description: z.string(),
                        date: z.string(),
                        time: z.string()
                    })
                ).length(3),
                footer: z.object({
                    description: z.string(),
                    sections: z.array(
                        z.object({
                            title: z.string(),
                            links: z.array(
                                z.object({
                                    label: z.string(),
                                    href: z.string()
                                })
                            ).min(3).max(5)
                        })
                    ).length(3),
                    social: z.array(
                        z.object({
                            platform: z.string(),
                            href: z.string()
                        })
                    ).min(3).max(5),
                    contact: z.object({
                        email: z.string(),
                        phone: z.string(),
                        location: z.string()
                    })
                })
            })
        );

        const formatInstructions = parser.getFormatInstructions();

        const prompt = new PromptTemplate({
            template: `
                You are an expert in business marketing and content creation.
                Generate engaging website content for a business with these details:

                Business Name: {businessName}
                Industry: {industry}
                Description: {description}
                Website Goal: {websiteGoal}

                Create compelling and professional content that highlights the unique value of this business.
                Focus on {websiteGoal} as the main objective.

                Important: Structure your response as valid JSON with these exact fields:
                - hero object with title and subtitle strings
                - navigation object with links array (exactly 4 items) and cta object for the main call-to-action
                - services title string that introduces the services section
                - services array containing exactly 3 objects with title and description strings
                - events array containing exactly 3 objects with title, description, date (YYYY-MM-DD), and time (HH:MM) strings
                - footer object containing:
                  - business description that captures the essence of the business
                  - 3 sections with unique titles (like Company, Legal, Support) and 3-5 relevant links each
                  - 3-5 social media links appropriate for this type of business
                  - realistic contact information (email, phone, location)

                {format_instructions}

                The content should be:
                1. Professional and engaging
                2. Specific to the business type
                3. Focused on their unique offerings
                4. Action-oriented
                5. SEO-friendly

                For events, use realistic future dates starting from today within the next 3 months.
                
                For navigation:
                1. Include relevant page links based on the business type
                2. Use clear, concise labels
                3. Make the CTA compelling and action-oriented

                For footer content:
                1. Write a compelling business description that builds trust
                2. Create relevant section titles and links that make sense for this business
                3. Choose social platforms that match the business's target audience
                4. Generate realistic contact information appropriate for the business type
            `,
            inputVariables: ["businessName", "industry", "description", "websiteGoal"],
            partialVariables: { format_instructions: formatInstructions }
        });

        const model = new ChatOpenAI({
            temperature: 0.7,
            modelName: "gpt-4",
            openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });

        const input = await prompt.format({
            businessName: formData.businessName,
            industry: formData.industry,
            description: formData.description,
            websiteGoal: websiteGoal
        });

        const response = await model.call([
            {
                role: "system",
                content: "You are a professional website content generator that creates engaging, conversion-focused content for businesses. Always return content in valid JSON format."
            },
            {
                role: "user",
                content: input
            }
        ]);

        console.log('Raw response from LangChain:', response.content);
        const parsedContent = await parser.parse(response.content);
        console.log('Parsed content:', parsedContent);
        return parsedContent;

    } catch (error) {
        console.error('Error generating content:', error);
        throw error;
    }
}

export async function generateUnsplashSearchTerm(formData, websiteGoal) {
    const prompt = new PromptTemplate({
        template: `            
            Your task is to review the business details and match that to a general business category. This is the search term you provide.

            Business Details:
            Name: {businessName}
            Industry: {industry}
            Description: {description}
            Website Goal: {websiteGoal}

            Requirements:
            - Return ONLY the search term, no other text
            - Use 1 word that best describe the business category
            - Focus on the core business type
            - Use general professional terms that will return high-quality images

            Return only the search term, nothing else.
        `,
        inputVariables: ["businessName", "industry", "description", "websiteGoal"],
    });

    const model = new ChatOpenAI({
        temperature: 0.3, // Lower temperature for more focused results
        modelName: "gpt-4",
        openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });

    const input = await prompt.format({
        businessName: formData.businessName,
        industry: formData.industry,
        description: formData.description,
        websiteGoal: websiteGoal
    });

    const response = await model.call([
        {
            role: "system",
            content: "You are a professional image curator. Return only the search term, no explanations or additional text."
        },
        {
            role: "user",
            content: input
        }
    ]);

    // Clean up the response to ensure it's just the search term
    const searchTerm = response.content.trim().toLowerCase().replace(/"/g, '');
    console.log('Generated search term:', searchTerm);
    return searchTerm;
}

// Update the main content generation function
export async function generateWebsiteContent(formData, websiteGoal) {
    let previewContent = null;

    try {
        // First generate the content
        previewContent = await generateContentWithLangChain(formData, websiteGoal);
        console.log('Generated content with navigation and footer:', previewContent);

        // Generate the optimal search term
        const searchTerm = await generateUnsplashSearchTerm(formData, websiteGoal);

        // Fetch a batch of images using the generated search term
        const images = await searchUnsplashImages(searchTerm, 10);

        // Ensure we have unique images by shuffling the array
        const shuffledImages = [...images].sort(() => Math.random() - 0.5);

        // Distribute images across sections
        const contentWithImages = {
            ...previewContent, // Preserve all generated content including navigation and footer
            hero: {
                ...previewContent.hero,
                image: shuffledImages[0] || {
                    url: '/default-hero.jpg',
                    alt: previewContent.hero.title,
                    credit: null
                }
            },
            services: previewContent.services.map((service, index) => ({
                ...service,
                image: shuffledImages[index + 1] || {
                    url: '/default-service.jpg',
                    alt: service.title,
                    credit: null
                }
            })),
            events: previewContent.events.map((event, index) => ({
                ...event,
                image: shuffledImages[index + 4] || {
                    url: '/default-event.jpg',
                    alt: event.title,
                    credit: null
                }
            }))
        };

        console.log('Final content with images:', contentWithImages);
        return contentWithImages;

    } catch (error) {
        console.error('Error in generateWebsiteContent:', error);

        // Return the generated content without images if image fetching fails
        if (error.message.includes('Failed to fetch images')) {
            console.log('Returning content without images due to Unsplash API error');
            return previewContent;
        }

        throw error;
    }
}

export async function refineWebsiteContent(formData, websiteGoal, taskData, existingContent) {
    try {
        const parser = StructuredOutputParser.fromZodSchema(
            z.object({
                hero: z.object({
                    title: z.string(),
                    subtitle: z.string()
                }),
                services: z.array(
                    z.object({
                        title: z.string(),
                        description: z.string()
                    })
                ),
                events: z.array(
                    z.object({
                        title: z.string(),
                        description: z.string(),
                        date: z.string(),
                        time: z.string()
                    })
                )
            })
        );

        const formatInstructions = parser.getFormatInstructions();

        const prompt = new PromptTemplate({
            template: `
                You are an expert in business marketing and content creation.
                Refine and enhance the existing website content using new information about the business.

                Business Details:
                Name: {businessName}
                Industry: {industry}
                Description: {description}
                Website Goal: {websiteGoal}

                Additional Information:
                Target Audience: {targetAudience}
                Unique Value Proposition: {uniqueValue}
                Additional Details: {additionalInfo}

                Current Content:
                {currentContent}

                Using all this information, create enhanced website content that:
                1. Better targets the specified audience
                2. Highlights the unique value proposition
                3. Incorporates the additional details
                4. Maintains the business's tone and style
                5. Optimizes for {websiteGoal}

                {format_instructions}

                For events, use realistic future dates starting from today within the next 3 months.
            `,
            inputVariables: [
                "businessName",
                "industry",
                "description",
                "websiteGoal",
                "targetAudience",
                "uniqueValue",
                "additionalInfo",
                "currentContent"
            ],
            partialVariables: { format_instructions: formatInstructions }
        });

        const model = new ChatOpenAI({
            temperature: 0.7,
            modelName: "gpt-4",
            openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });

        const input = await prompt.format({
            businessName: formData.businessName,
            industry: formData.industry,
            description: formData.description,
            websiteGoal: websiteGoal,
            targetAudience: taskData.audience || '',
            uniqueValue: taskData.usp || '',
            additionalInfo: taskData.additionalInfo || '',
            currentContent: JSON.stringify(existingContent, null, 2)
        });

        const response = await model.call([
            {
                role: "system",
                content: "You are a professional website content generator that refines and enhances existing content based on new information. Always maintain consistency and improve upon the existing content while incorporating new details."
            },
            {
                role: "user",
                content: input
            }
        ]);

        console.log('Raw response:', response.content);

        const parsedContent = await parser.parse(response.content);

        // Preserve existing images
        return {
            hero: {
                ...parsedContent.hero,
                image: existingContent.hero?.image
            },
            services: parsedContent.services.map((service, index) => ({
                ...service,
                image: existingContent.services?.[index]?.image
            })),
            events: parsedContent.events.map((event, index) => ({
                ...event,
                image: existingContent.events?.[index]?.image
            }))
        };

    } catch (error) {
        console.error('Error refining content:', error);
        throw error;
    }
}

export async function generateFormSuggestions(formData, websiteGoal) {
    const prompt = new PromptTemplate({
        template: `
            You are an expert in creating effective contact forms and booking systems.
            Generate form suggestions for a business with these details:

            Business Name: {businessName}
            Industry: {industry}
            Description: {description}
            Website Goal: {websiteGoal}

            Create 3 form options that would best serve this business.
            Each form should include:
            - A title
            - A description explaining why this form type is good for their business
            - 3-4 benefits of using this form type
            - A list of suggested form fields
            - A title for the form itself to tell the visitor what it is
            - A description explaining the visitor what they can achieve by filling out the form
     

            Return the data in this valid JSON format:
            {{
                "forms": [
                    {{
                        "id": "string",
                        "title": "string",
                        "description": "string",
                        "benefits": ["string"],
                        "fields": ["string"],
                        "formTitle": ["string"],
                        "formDescription": ["string"]
                    }}
                ]
            }}               

            Make sure each form is:
            1. Specific to the business type
            2. Aligned with their goals
            3. User-friendly
            4. Conversion-optimized
            
            Return only the JSON, no additional text.
        `,
        inputVariables: ["businessName", "industry", "description", "websiteGoal"],
    });

    const model = new ChatOpenAI({
        temperature: 0.7,
        modelName: "gpt-4",
        openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });

    try {
        const input = await prompt.format({
            businessName: formData.businessName,
            industry: formData.industry,
            description: formData.description,
            websiteGoal: websiteGoal
        });

        const response = await model.call([
            {
                role: "system",
                content: "You are a form design expert who creates effective, conversion-optimized forms for businesses."
            },
            {
                role: "user",
                content: input
            }
        ]);

        // Parse and return the response
        const parsedResponse = JSON.parse(response.content);
        console.log('Generated form suggestions:', parsedResponse);
        return parsedResponse;

    } catch (error) {
        console.error('Error generating form suggestions:', error);
        throw error;
    }
}