// src/components/CMSView/Preview/EventsSection.jsx
import React from 'react';
import { Calendar, Clock, ArrowRight } from 'lucide-react';
import Button from "../../../shared/Button";

const EventsSection = ({ items = [] }) => {
    const displayItems = items.length > 0 ? items : [];

    return (
        <div className="py-24 bg-gray-50">
            <div className="max-w-7xl mx-auto px-6 lg:px-8">
                {/* Section Header */}
                <div className="max-w-2xl mx-auto text-center mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                        Upcoming Events
                    </h2>
                    <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
                    <p className="text-lg text-gray-600">
                        Join us for unique culinary experiences
                    </p>
                </div>

                {/* Events Grid */}
                <div className="grid gap-8">
                    {displayItems.map((event, index) => (
                        <div
                            key={index}
                            className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300"
                        >
                            <div className="flex flex-col md:flex-row">
                                {/* Image */}
                                <div className="md:w-1/3 relative">
                                    <img
                                        src={event.image?.url || '/default-event.jpg'}
                                        alt={event.image?.alt || event.title}
                                        className="w-full h-64 md:h-full object-cover"
                                    />
                                    {event.image?.credit && (
                                        <a
                                            href={event.image.credit.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="absolute bottom-2 right-2 text-xs text-white/70 bg-black/30 px-2 py-1 rounded"
                                        >
                                            Photo: {event.image.credit.name}
                                        </a>
                                    )}
                                </div>

                                {/* Content */}
                                <div className="md:w-2/3 p-8 flex flex-col justify-between">
                                    <div>
                                        <div className="flex items-center gap-4 text-sm text-blue-600 mb-4">
                                            <div className="flex items-center">
                                                <Calendar className="h-4 w-4 mr-1" />
                                                {event.date}
                                            </div>
                                            <div className="flex items-center">
                                                <Clock className="h-4 w-4 mr-1" />
                                                {event.time}
                                            </div>
                                        </div>

                                        <h3 className="text-2xl font-bold text-gray-900 mb-4">
                                            {event.title}
                                        </h3>

                                        <p className="text-gray-600 mb-6 leading-relaxed">
                                            {event.description}
                                        </p>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <Button
                                            className="bg-blue-600 hover:bg-blue-700"
                                            size="lg"
                                        >
                                            Book Now
                                            <ArrowRight className="h-4 w-4 ml-2" />
                                        </Button>

                                        <Button
                                            variant="outline"
                                            size="lg"
                                        >
                                            Learn More
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EventsSection;